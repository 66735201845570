define("discourse/plugins/discourse-landing-pages/discourse/components/modal/import-pages", ["exports", "@ember/component", "discourse/plugins/discourse-landing-pages/discourse/models/landing-page", "discourse/lib/ajax-error", "@ember/object", "@ember/service", "@ember/template-factory"], function (_exports, _component, _landingPage, _ajaxError, _object, _service, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <DModal
    class="upload-selector import-pages"
    @title={{i18n "admin.landing_pages.import.title"}}
    @closeModal={{@closeModal}}
  >
    <:body>
      <div class="inputs">
        <input
          onchange={{action "uploadFile"}}
          type="file"
          id="file-input"
          accept=".zip,application/zip"
        /><br />
        <span class="description">{{i18n
            "admin.landing_pages.import.file_tip"
          }}</span>
      </div>
    </:body>
  
    <:footer>
      <DButton
        @action={{this.importPage}}
        @disabled={{this.importDisabled}}
        class="btn btn-primary"
        @label="admin.landing_pages.import.button"
      />
      <DModalCancel @close={{@closeModal}} />
    </:footer>
  </DModal>
  */
  {
    "id": "TAY6+vEV",
    "block": "[[[8,[39,0],[[24,0,\"upload-selector import-pages\"]],[[\"@title\",\"@closeModal\"],[[28,[37,1],[\"admin.landing_pages.import.title\"],null],[30,1]]],[[\"body\",\"footer\"],[[[[1,\"\\n    \"],[10,0],[14,0,\"inputs\"],[12],[1,\"\\n      \"],[10,\"input\"],[15,\"onchange\",[28,[37,2],[[30,0],\"uploadFile\"],null]],[14,1,\"file-input\"],[14,\"accept\",\".zip,application/zip\"],[14,4,\"file\"],[12],[13],[10,\"br\"],[12],[13],[1,\"\\n      \"],[10,1],[14,0,\"description\"],[12],[1,[28,[35,1],[\"admin.landing_pages.import.file_tip\"],null]],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[]],[[[1,\"\\n    \"],[8,[39,3],[[24,0,\"btn btn-primary\"]],[[\"@action\",\"@disabled\",\"@label\"],[[30,0,[\"importPage\"]],[30,0,[\"importDisabled\"]],\"admin.landing_pages.import.button\"]],null],[1,\"\\n    \"],[8,[39,4],null,[[\"@close\"],[[30,1]]],null],[1,\"\\n  \"]],[]]]]]],[\"@closeModal\"],false,[\"d-modal\",\"i18n\",\"action\",\"d-button\",\"d-modal-cancel\"]]",
    "moduleName": "discourse/plugins/discourse-landing-pages/discourse/components/modal/import-pages.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, _component.default.extend(dt7948.p({
    dialog: (0, _service.service)(),
    uploadFile() {
      this.set("pageFile", $("#file-input")[0].files[0]);
    },
    importPage() {
      let data = new FormData();
      data.append("page", this.pageFile);
      this.set("loading", true);
      _landingPage.default.import(data).then(result => {
        this.closeModal(result);
      }).catch(function (e) {
        if (typeof e === "string") {
          this.dialog.alert(e);
        } else {
          (0, _ajaxError.popupAjaxError)(e);
        }
      }).finally(() => this.set("loading", false));
    }
  }, [["method", "uploadFile", [_object.action]], ["method", "importPage", [_object.action]]])));
});